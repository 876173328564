import React from 'react'

import { Container } from '@components/Container'
import { TopChartSection } from '@components/TopChartSection'
import { BookTopChartProvider } from '@hooks/book/useBookTopChart'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'
import { GoToTopButton } from '@components/GoToTopButton'
import { Popup } from '@components/Popup'
import { PopupBannerScreenEnum } from '@interfaces/PopupBannerScreenEnum'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { BannerSection } from './components/BannerSection'
import { HighlightBanner } from './components/HighlightBanner'
import { ContinueToRead } from './components/ContinueToRead'
import { BookTopChart } from './components/BookTopChart'
import { CategoryMenu } from './components/CategoryMenu'
import { DailyTopChartCharacter } from './components/DailyTopChartCharacter'
import { EbookDiscount } from './components/EbookDiscount'
import { EbookNew } from './components/EbookNew'
import { HomePageProps } from './interface'
import { LatestUpdateBook } from './components/LatestUpdateBook'
import { Newest } from './components/Newest'

export const HomePage = React.memo(
  ({
    serverDate,
    highlightBannersTop,
    highlightBannersBottom,
    isLoading,
  }: HomePageProps) => {
    return (
      <>
        <BannerSection />
        <Container className='tablet-mobile:mt-[30px] tablet-mobile:!py-0 desktop:!py-[0px]'>
          <CategoryMenu className='desktop:hidden pb-[40px] mobile:pl-[20px]' />
          <ContinueToRead />
          <LatestUpdateBook
            className='desktop:pb-[60px] tablet-mobile:pb-[40px]'
            direction='horizontal'
            isShowNavigation
          />
          <Newest
            className='desktop:pb-[60px] tablet-mobile:pb-[40px]'
            direction='horizontal'
            isShowNavigation
          />
          {SHOW_EBOOK_FEATURE && (
            <>
              <EbookDiscount direction='horizontal' isShowNavigation />
              <EbookNew
                className='desktop:pb-[60px] tablet-mobile:pb-[40px]'
                direction='horizontal'
                isShowNavigation
              />
            </>
          )}
          <DailyTopChartCharacter />
        </Container>
        <HighlightBanner
          className='desktop:pt-[60px] tablet-mobile:pt-[40px]'
          highlightBanners={highlightBannersTop}
          isLoading={isLoading}
        />
        <section className='flex flex-col'>
          <BookTopChartProvider
            bookTopChartType={BookTopChartEnum.VIEW}
            limit={10}
            serverDate={serverDate}
          >
            <BookTopChart
              title='เรื่องยอดนิยม'
              className='mt-[60px] tablet-mobile:mt-[40px]'
              type='top-view'
              disabledClassName='opacity-30'
            />
          </BookTopChartProvider>
          <BookTopChartProvider
            bookTopChartType={BookTopChartEnum.PURCHASE}
            limit={10}
            serverDate={serverDate}
          >
            <BookTopChart
              title='เรื่องฮิตขายดี'
              className='mt-[40px] tablet-mobile:mt-[20px]'
              type='top-purchase'
              disabledClassName='opacity-30'
            />
          </BookTopChartProvider>
        </section>
        <TopChartSection
          className='mt-[40px]  tablet-mobile:mt-[20px] tablet-mobile:mb-[40px] bg-character-bg dark:bg-character-bg-dark bg-no-repeat bg-cover px-[100px] py-[50px] tablet-mobile:px-0 tablet-mobile:py-[38px] h-full'
          classNameTitle='text-white tablet-mobile:px-[20px]'
          selectClassName='text-[#94e7ff]'
          title='ท็อปชาร์ตตัวละคร'
          canViewAll
        />
        <HighlightBanner
          className='my-[40px]'
          highlightBanners={highlightBannersBottom}
          isLoading={isLoading}
        />
        <GoToTopButton className='desktop:hidden bottom-[72px]' />
        <Popup screen={PopupBannerScreenEnum.Home} />
      </>
    )
  }
)
