import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function DailyCharacterCard({
  bookId,
  characterImgPath,
  characterResizeImgPath,
  characterName,
  bookTitle,
}: {
  bookId: number
  characterImgPath: string | null
  characterResizeImgPath: string | null
  characterName: string
  bookTitle: string
}) {
  return (
    <Link href={`/story/${bookId}`} legacyBehavior prefetch={false}>
      <a>
        <div className='text-center max-w-[142px]'>
          <div className='bg-[#33cfff] dark:bg-primary w-[142px] h-[142px] rounded-full flex items-center justify-center mb-[10px]'>
            <div className='bg-white dark:bg-dark-9 w-[134px] h-[134px] rounded-full flex items-center justify-center'>
              <div className='relative w-[126px] h-[126px] rounded-full overflow-hidden'>
                <Image
                  src={
                    characterResizeImgPath ||
                    characterImgPath ||
                    '/images/avatar.png'
                  }
                  alt={characterName}
                  layout='fill'
                  sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                  quality={25}
                />
              </div>
            </div>
          </div>
          <div className='text-sm font-light font-sarabun text-white mb-[4px] line-clamp-1 break-all leading-[1.8]'>
            {bookTitle}
          </div>
          <div className='text-sm font-bold font-sarabun text-white line-clamp-1 break-all leading-[1.8]'>
            {characterName}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default DailyCharacterCard
