import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Swiper } from '@features/home/components/CustomSwiper'
import { DailyCharacterIcon } from '@components/icons/DailyCharacterIcon'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import DailyCharacterCard from './components/DailyCharacterCard'
import { DailyTopChartCharacterProps } from './interface'

const DailyTopChartCharacter = React.memo(
  ({
    data,
    isLoading,
    isDesktop,
    isMobile,
    isTablet,
  }: DailyTopChartCharacterProps) => {
    return (
      <Container className='desktop:!pb-[0px] !pt-0 tablet:!px-0 mobile:!px-[21px]'>
        <div className='font-mitr flex mb-[20px] items-center'>
          <DailyCharacterIcon className='text-primary w-[38px] h-[38px] mr-[10px] mobile:w-[24px] mobile:h-[24px]' />
          <h2 className='flex-1 topic-base mobile:topic-small dark:text-primary'>
            Daily top Chart Character
          </h2>
        </div>
        {isLoading ? (
          <div className='py-[50px] flex justify-center'>
            <Loading />
          </div>
        ) : (
          data && (
            <div className='bg-daily-character dark:bg-daily-character-dark bg-cover bg-no-repeat h-[272px] w-full rounded-[40px]'>
              <Swiper
                slidesPerView={isDesktop ? 7 : isMobile ? 2 : 4}
                isShowNavigation
                prevclass='left-[-48px] tablet:left-[20px]'
                nextclass='right-[-48px] tablet:right-[20px]'
                count={
                  isDesktop ? 0 : isTablet ? data.length - 3 : data.length - 1
                }
                paginationClassName='tablet-mobile:mt-[30px] my-0'
                speed={1000}
              >
                {data?.map(character => (
                  <SwiperSlide key={character.characterId}>
                    <DailyCharacterCard
                      bookId={character.bookId}
                      characterImgPath={character.characterImgPath}
                      characterResizeImgPath={character.characterResizeImgPath}
                      characterName={character.characterName}
                      bookTitle={character.bookTitle}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        )}
      </Container>
    )
  }
)

export default DailyTopChartCharacter
