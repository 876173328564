import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import { useDropdown } from '@hooks/useDropdown'
import { ChevronDownIcon } from '@components/icons/ChevronDownIcon'
import { OptionSelectProps } from './interface'

export const OptionSelect = ({
  title,
  options,
  value,
  className,
  selectClassName,
  valueClassName,
  handleSelect,
  isHomePage = false,
}: OptionSelectProps) => {
  const range = 20
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const [round, setRound] = useState(1)

  const selectedOption = useMemo(() => {
    return options?.find(row => row.value === value)
  }, [options, value])

  return (
    <div className={cn('relative ', className)}>
      <div
        ref={dropdownRef}
        className={cn(
          'w-full font-mitr h-[40px] border border-gray-3 bg-white rounded-[8px] text-[14px] text-secondary dark:text-dark-10 font-light cursor-pointer',
          { 'border-primary': isShowDropdown },
          selectClassName
        )}
        onClick={toggleDropdown}
      >
        <div className='flex h-full items-center justify-end space-x-[5px] mobile:justify-start'>
          <div className='flex items-center line-clamp-1 break-word leading-6'>
            {title && <span className='text-secondary-1'>{title} : </span>}
            <span
              className={cn('font-medium mr-[5px]', {
                'text-white h-[20px]': isHomePage,
                'text-secondary-1': !isHomePage,
              })}
            >
              ประจำเดือน
            </span>
            <span className={cn('font-bold', valueClassName)}>
              {`${selectedOption?.label} ${selectedOption?.subLabel}`}
            </span>
          </div>
          <ChevronDownIcon
            width='16'
            height='16'
            className={cn('min-w-[16px]', {
              'text-primary': !isHomePage,
              'text-white': isHomePage,
            })}
          />
        </div>
        <div
          className={cn(
            'absolute w-full mobile:w-fit mobile:left-0 z-10 mt-[3px] py-[10px] right-0 bg-white border dark:bg-dark-8 border-gray-4 dark:border-dark-1 rounded-[10px] max-h-[400px] overflow-y-auto shadow-dropdown font-sarabun',
            { hidden: !isShowDropdown }
          )}
          onScroll={(e: any) => {
            const scrollHeight = e.target.scrollHeight - 10
            const scrollTop = e.target.scrollTop + e.target.offsetHeight

            if (
              scrollHeight <= scrollTop &&
              options.length !== options.slice(0, round * range).length
            ) {
              setRound(prev => prev + 1)
            }
          }}
        >
          {options.slice(0, round * range).map((row, index) => (
            <div
              className='grid grid-cols-2 items-center hover:bg-primary-1 dark:hover:bg-dark-9 hover:text-primary  px-[15px] leading-8 text-secondary dark:text-dark-10'
              key={index}
              onClick={() => {
                handleSelect(row.value)
              }}
            >
              <div className='line-clamp-1 break-word'>{row.label}</div>
              <div className='text-center'>{row.subLabel}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
