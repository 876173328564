import Image from 'next/image'
import { SwiperSlide } from 'swiper/react'
import { Swiper } from '@features/home/components/CustomSwiper'
import { PosterBannerType } from '@models/banner/PosterBannerType'
import { useWindowSize } from '@hooks/useWindowSize'
import { useBannerAction } from '@hooks/banner/useBannerAction'
import { PosterBannersByHighlightGroupIdResponse } from '@models/banner/PosterBannersByHighlightGroupIdResponse'
import { useInfiniteQuery } from 'react-query'

export function HighlightPosterBanner({
  id,
  bannerName,
  banners,
}: {
  id: number
  bannerName: string
  banners: PosterBannerType[]
}) {
  const { isDesktop } = useWindowSize()
  const bannerAction = useBannerAction()

  const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
    ['poster-banner', id],
    async ({ pageParam = 1 }) => {
      const res = await bannerAction.getPosterBannersByHighlightGroupId({
        highlightGroupId: id,
        page: pageParam,
      })

      return res
    },
    {
      getNextPageParam: (lastPage: PosterBannersByHighlightGroupIdResponse) => {
        if (lastPage.data.length) return lastPage.page + 1

        return undefined
      },
      initialData: {
        pages: [{ data: banners, page: 1 }],
        pageParams: [null],
      },
      enabled: false,
    }
  )

  function handleFetchNextPage(value: number) {
    if (data && !isFetchingNextPage && value > (data.pages.length - 1) * 10) {
      fetchNextPage()
    }
  }

  return (
    <div className='flex flex-col justify-center w-full h-fit max-w-[1160px] mx-auto'>
      <h2 className='topic-base mobile:topic-small dark:text-primary font-mitr text-left tablet-mobile:mx-[20px] mb-[20px] break-word line-clamp-1 '>
        {bannerName}
      </h2>
      {!!data?.pages.length && (
        <Swiper
          slidesPerView={isDesktop ? 3 : 'auto'}
          spaceBetween={15}
          isShowNavigation
          prevclass='left-[-48px] tablet:left-[20px]'
          nextclass='right-[-48px] tablet:right-[20px]'
          handleOnSlideChange={handleFetchNextPage}
          lazy
          speed={300}
        >
          {data.pages.map(page =>
            page.data.map(item => (
              <SwiperSlide
                className='tablet-mobile:first:ml-[15px] tablet-mobile:last:mr-[15px]'
                key={item.id}
              >
                <a href={item.linkWeb} target='_blank' rel='noreferrer'>
                  <div className='relative w-[378px] h-[162px] mobile:w-[322px] mobile:h-[138px] cursor-pointer'>
                    <Image
                      layout='fill'
                      className='rounded-[8px] object-cover'
                      src={item.imgPath}
                      alt={item.description}
                      sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                      quality={50}
                    />
                  </div>
                </a>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      )}
    </div>
  )
}
