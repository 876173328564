import React from 'react'
import cn from 'classnames'
import { DirectionType } from '@interfaces/DirectionType'

function StoryCardSkeleton({ direction }: { direction?: DirectionType }) {
  return (
    <div
      className={cn(
        'animate-pulse rounded-[8px] shadow border-[0.5px] border-gray-3 dark:border-purple flex p-[14px] space-x-[20px] tablet-mobile:p-[10px] tablet-mobile:flex-col tablet:w-[182px] mobile:w-[164px] tablet-mobile:h-[390px]',
        {
          'w-[276px] h-[632px] flex-col': direction === 'vertical',
          'w-[573px] h-[227px]': direction === 'horizontal',
        }
      )}
    >
      <div
        className={cn(
          'bg-gray-3 dark:bg-dark-8 rounded-[8px] tablet:w-[162px] mobile:w-[144px] tablet-mobile:h-[197px]',
          {
            'w-[246px] h-[344px]': direction === 'vertical',
            'w-[140px] h-[197px]': direction === 'horizontal',
          }
        )}
      />
      <div
        className={cn('flex flex-col space-y-[20px] tablet-mobile:pt-[10px]', {
          'pt-[20px]': direction === 'vertical',
          'pt-[5px]': direction === 'horizontal',
        })}
      >
        <div className='h-[20px] w-[120px] bg-gray-3 dark:bg-dark-8 rounded-[8px] tablet-mobile:w-[60px]' />
        <div className='h-[20px] w-[200px] bg-gray-3 dark:bg-dark-8 rounded-[8px] tablet-mobile:w-[100px]' />
        <div className='h-[20px] w-[180px] bg-gray-3 dark:bg-dark-8 rounded-[8px] tablet-mobile:hidden' />
        <div
          className={cn(
            'h-[20px] bg-gray-3 dark:bg-dark-8 rounded-[8px] tablet-mobile:w-[144px] tablet-mobile:mt-[50px]',
            {
              'mt-[70px] w-[246px]': direction === 'vertical',
              'mt-[20px] w-[320px]': direction === 'horizontal',
            }
          )}
        />
      </div>
    </div>
  )
}

export default StoryCardSkeleton
