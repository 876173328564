import React from 'react'
import Link from 'next/link'

import { ContinueToReadCard } from '@components/ContinueToReadCard'
import { EyeIcon } from '@components/icons/EyeIcon'
import { Right2Icon } from '@components/icons/Right2Icon'
import { ContinueToReadProps } from './interface'

export default function ContinueToRead({
  userReadings,
  isAuthenticated,
  handleDeleteUserReading,
}: ContinueToReadProps) {
  return (
    <div className='text-secondary dark:text-white'>
      <div className='font-mitr flex mb-[20px] items-center mobile:mb-[10px] mobile:mx-[21px]'>
        <EyeIcon className='text-primary w-[38px] h-[38px] mr-[10px] mobile:w-[24px] mobile:h-[24px]' />
        <h2 className='flex-1 topic-base mobile:topic-small dark:text-primary'>
          อ่านต่อ
        </h2>
        {isAuthenticated && (
          <Link href='/my-reading/continue' legacyBehavior prefetch={false}>
            <a className='flex items-center cursor-pointer select-none text-[14px] font-medium dark:text-primary'>
              ดูทั้งหมด&nbsp;
              <Right2Icon width='14' height='14' />
            </a>
          </Link>
        )}
      </div>
      <div className='overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar pb-[60px] tablet-mobile:pb-[40px]'>
        <div className='flex space-x-[20px]'>
          {userReadings.map(row => (
            <ContinueToReadCard
              className='w-[375px] mobile:w-[300px]'
              id={row.id}
              key={row.id}
              dataTest={`continue-read-${row.bookTitle}`}
              chapterName={row.chapterName}
              bookCoverImgPath={row.coverImgPath}
              bookTitle={row.bookTitle}
              bookWriter={row.bookWriter}
              updatedAt={row.updatedAt}
              handleRemove={handleDeleteUserReading}
              direction='horizontal'
              url={`/story/${row.bookId}/chapter/${row.chapterId}`}
              writerUserId={row.writerUserId}
              slug={row.profilePageSlug}
              showContinueToRead
            />
          ))}
        </div>
      </div>
    </div>
  )
}
